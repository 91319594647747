body {
  height: 100vh;
  background: rgb(0, 0, 0);
  /* background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(41, 84, 98, 1) 0%, rgba(56, 40, 43, 1) 0%, rgba(60, 27, 27, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(22, 37, 57, 1) 100%, rgba(110, 120, 134, 1) 100%, rgba(0, 212, 255, 1) 100%); */
}

input[type="submit"]:disabled {
  background: #4a4a4a;
}

input[type="submit"] {
  border: none;
  padding: 10px 20px;
  background: linear-gradient(130deg, #6f6df4, #4c46f5);
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
}

.submit {
  border: none;
  padding: 10px 20px;
  background: white;
  border: 1px solid black;
  color: black;
  border-radius: 3px;
  cursor: pointer;
}

.btnbg {
  background: linear-gradient(130deg, #6f6df4, #4c46f5);
}
