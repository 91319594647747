@import url("https://fonts.cdnfonts.com/css/arcade-classic");
nav {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: end;
  overflow: hidden;
  position: fixed;
  top: 9px;
  z-index: 15;
}

.menu-and-logo {
  margin-right: 4rem;
}

#nav-btn {
  z-index: 15;
}
.menu-and-logo img {
  height: 60px;
}
#nav-logo {
  position: absolute !important;
  width: 90px;
  top: -15px;
  left: 20px;
  cursor: pointer;
  border-radius: 20px;
}

@media screen and (min-width: 500px) {
  #nav-logo {
    width: 75px;
    left: 50px;
    transform: scale(1.4);
    top: 0;
  }
}
.nav-drop {
  /* background-image: url("../public/tech_assets/87407.jpg"); */
  background-repeat: cover;
  background-size: contain;
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  background-color: #e0e0e0;
  position: fixed;
  top: -100%;
  z-index: 20;
  transition: all 0.5s ease-in;
}

.nav-close-btn {
  background-color: #bbfdd1;
}

.nav-close-btn img {
  height: 70px;
}

.nav-cont {
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.link-items {
  width: 80%;
  height: 350px;
  gap: 3rem;
  margin-left: 5rem;
  background-color: yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: 900;
  letter-spacing: 10px;
  cursor: pointer;
  margin-top: 4rem;
  box-shadow: -10px 10px;
  transition: transform 0.5s ease;
  font-family: "ArcadeClassic", sans-serif;
  color: black;
}

.link-items:hover {
  background-color: #578ff7;
  box-shadow: -10px 10px;
  transform: scale(1.1);
}

/*@media screen and (min-width: 700px){*/
/*.link-items:hover{*/
/*transform: scale(1.1);*/
/*}*/
/*}*/

.close-btn {
  width: 97%;
  margin-right: 10%;
  display: flex;
  justify-content: flex-end;
  gap: 4rem;
}

.close-btn img {
  height: 65px;
  margin-right: 2.5rem;
  margin-top: 2rem;
}

@media screen and (max-width: 600px) {
  .link-items {
    font-size: large;
  }
  .close-btn {
    margin-right: 0;
  }
  .nav-drop {
    grid-template-columns: 1fr;
    background-image: none;
  }
  .nav-close-btn {
    display: none;
  }
  .nav-cont {
    margin-right: 5rem;
  }
  .link-items {
    margin-left: 10%;
  }
  .close-btn img {
    height: 60px;
    margin-right: 0;
  }
}

@media screen and (max-width: 360px) {
  .link-items {
    margin-left: 5%;
  }
}

@media screen and (max-width: 500px) {
  .link-items {
    font-size: 0.8rem;
    width: 100%;
    margin-left: 2.5rem;
    font-size: 1.4rem;
    height: 6rem;
  }
  .menu-and-logo {
    margin-right: 2rem;
  }
  .nav-drop {
    top: -110%;
  }
  .close-btn {
    padding-left: 3rem;
  }
  .close-btn img {
    margin-left: 15rem;
    margin-right: -3rem;
    margin-top: 10px;
  }
}

.nav-drop.toggle {
  top: 0;
}
@import url("https://fonts.cdnfonts.com/css/arcade-classic");
