.Dropdown-root {
    position: relative;
    top: -5px;
}

.Dropdown-control {
    background-image: url('../../assets/icons/DropDownIcon.svg');
    background-position: 97% 50%;
    background-repeat: no-repeat;
    background-size: 10px;
    width: 100%;
    border: 1.2px solid #c4cae0;
    height: 40px;
    overflow: hidden;
    background-color: white;
    border: 1px solid;
    border-radius: 5px;
    box-sizing: border-box;
    color: #D3D3D3;
    cursor: pointer;
    padding-left: 30px;
    font-size: 15px;
    font-family: Inter;
    padding-top: 8px;
    transition: all 200ms ease;
}

.Dropdown-control:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}


.is-open .Dropdown-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
}

.Dropdown-menu {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
    padding: 10px;
}

.Dropdown-menu .Dropdown-group>.Dropdown-title {
    padding: 8px 10px;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    text-transform: capitalize;
}

.plnull {
    color: gray;
}

.plfull {
    color: black;
}

.Dropdown-option {
    box-sizing: border-box;
    color: black;
    font-family: "Inter";
    cursor: pointer;
    display: block;
    padding: 8px 10px;
    margin-top: 2px;
    background-color: whitesmoke;
}

.Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
    background-color: #f2f9fc;
    color: #333;
}

.Dropdown-option.is-selected {
    background-color: #f2f9fc;
    color: #333;
}

.Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px;
}