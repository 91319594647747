@import url("https://fonts.cdnfonts.com/css/arcade-classic");
.event-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  /* margin-top: 3rem; */
}

.dep-card {
  /* margin: 0; */
  /* padding: 0; */
  /* width: 80%; */
  /* position: relative; */
  /* overflow: hidden; */
  transition: all 0.5s ease;
}

.cont-wrap:hover .dep-card {
  filter: blur(1px);
}

.cont-wrap:hover .dep-card:hover {
  filter: none;
}

.dep-card:hover {
  transform: scale(1.1);
}
.menu-and-logo {
  z-index: 2;
}

.main-card {
  width: 100%;
  margin: 0;
  padding: 0;
}

#arrow {
  position: absolute;
  bottom: 15%;
  right: 8%;
}

#gif {
  width: 26.2%;
}

.sec-cont {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 15%;
  left: 5%;
}

.dep-name {
  width: 50%;
  color: white;
  font-size: 3rem;
  margin-right: 14rem;
  font-family: "ArcadeClassic", sans-serif;
  padding-left: 15px;
}

.arca {
  font-family: "ArcadeClassic", sans-serif;
}
@media screen and (max-width: 500px) {
  .dep-card {
    width: 100%;
  }

  .event-container {
    gap: -1rem;
    /* margin-top: -40px; */

    margin-top: 1rem;
  }
  #arrow {
    width: 40px;
  }

  .dep-name {
    font-size: 1.3rem;
    margin-right: 1rem;
    line-height: 1rem;
  }

  #gif {
    width: 24%;
  }

  .event-container {
    gap: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .dep-name {
    font-size: 1.2rem;
  }

  #arrow {
    width: 30px;
  }
}
