@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  overflow-x: hidden;
  user-select: none;
}
/* src/index.css */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.loader img {
  width: 100px; /* Adjust size as needed */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
